import React from 'react'

export default function Dex() {
  return (
    <div className='flex-center flex-col mt-20 md:mt-36 gap-10 w-[90%] mx-auto'>
        <h1 className='hidden md:block yapari monts font-extrabold text-[2rem] text-center mx-10 md:mx-auto'>
            Supported DEX
        </h1>
        <h1 className='md:hidden yapari text-4xl text-center mx-10 md:mx-auto'>
            <span className='text-xl textblue'>Supported </span><br />
            DEX
        </h1>
        <div className='flex items-center justify-center gap-10 flex-wrap max-w-[900px] rounded-xl border-[#8583f345] bg-[#83a6f30a] mx-auto w-full border p-5'>
            <img data-aos="fade-up" data-aos-duration="1500" src={require('../../assets/dex/1.png')} alt="Dex logo" className='w-[40px] md:w-[45px]' />
            <img data-aos="fade-up" data-aos-duration="1500" src={require('../../assets/dex/2.png')} alt="Dex logo" className='w-[40px] md:w-[45px]' />
            <img data-aos="fade-up" data-aos-duration="1500" src={require('../../assets/dex/3.png')} alt="Dex logo" className='w-[40px] md:w-[45px]' />
            <img data-aos="fade-up" data-aos-duration="1500" src={require('../../assets/dex/4.png')} alt="Dex logo" className='w-[40px] md:w-[45px]' />
            <img data-aos="fade-up" data-aos-duration="1500" src={require('../../assets/dex/5.png')} alt="Dex logo" className='w-[40px] md:w-[45px]' />
            <img data-aos="fade-up" data-aos-duration="1500" src={require('../../assets/dex/6.png')} alt="Dex logo" className='w-[40px] md:w-[45px]' />
            <img data-aos="fade-up" data-aos-duration="1500" src={require('../../assets/dex/7.png')} alt="Dex logo" className='w-[40px] md:w-[45px]' />
            <img data-aos="fade-up" data-aos-duration="1500" src={require('../../assets/dex/8.png')} alt="Dex logo" className='w-[40px] md:w-[45px]' />
            <img data-aos="fade-up" data-aos-duration="1500" src={require('../../assets/dex/9.png')} alt="Dex logo" className='w-[40px] md:w-[45px]' />
            <img data-aos="fade-up" data-aos-duration="1500" src={require('../../assets/dex/10.png')} alt="Dex logo" className='w-[40px] md:w-[45px]' />
            <img data-aos="fade-up" data-aos-duration="1500" src={require('../../assets/dex/11.png')} alt="Dex logo" className='w-[40px] md:w-[45px]' />
            <img data-aos="fade-up" data-aos-duration="1500" src={require('../../assets/dex/12.png')} alt="Dex logo" className='w-[40px] md:w-[45px]' />
            <img data-aos="fade-up" data-aos-duration="1500" src={require('../../assets/dex/13.png')} alt="Dex logo" className='w-[40px] md:w-[45px]' />
            <img data-aos="fade-up" data-aos-duration="1500" src={require('../../assets/dex/14.png')} alt="Dex logo" className='w-[40px] md:w-[45px]' />
            <img data-aos="fade-up" data-aos-duration="1500" src={require('../../assets/dex/15.png')} alt="Dex logo" className='w-[40px] md:w-[45px]' />
            <img data-aos="fade-up" data-aos-duration="1500" src={require('../../assets/dex/16.png')} alt="Dex logo" className='w-[40px] md:w-[45px]' />
            <img data-aos="fade-up" data-aos-duration="1500" src={require('../../assets/dex/17.png')} alt="Dex logo" className='w-[40px] md:w-[45px]' />
            <img data-aos="fade-up" data-aos-duration="1500" src={require('../../assets/dex/18.png')} alt="Dex logo" className='w-[40px] md:w-[45px]' />
            <img data-aos="fade-up" data-aos-duration="1500" src={require('../../assets/dex/19.png')} alt="Dex logo" className='w-[40px] md:w-[45px]' />
            <img data-aos="fade-up" data-aos-duration="1500" src={require('../../assets/chains/plus.png')} alt="Dex logo" className='w-[40px] md:w-[45px]' />
        </div>
    </div>
  )
}