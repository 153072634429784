import React from 'react'

export default function How() {
  return (
    <div className='w-full max-w-[950px] mx-auto mt-10 flex flex-col'>
        <h1 className="monts font-extrabold text-3xl md:text-[3rem] text-center mx-10 md:mx-auto mt-10 mb-10">How it Works</h1>
        <div className='flex items-center justify-between my-10 flex-col md:flex-row gap-16'>
            <div className='space-y-5 md:space-y-10 border-l-2 md:h- pl-4 py-5 border-[#83a6f3] relative'>
                <div className='w-[32px] h-[32px] border-2 border-[#8583f386] rounded-lg absolute top-[0px] -left-[15px] bg-[#141825] flex-center'>
                    <div className='w-[20px] h-[20px] rounded-md bg-[#8583F3]' />
                </div>
                <div className='w-[30px] h-[30px] border-2 border-[#8583f345] rounded-lg absolute -left-[15px] -bottom-[30px] bg-[#141825] flex-center'>
                    <div className='w-[10px] h-[10px] rounded-sm bg-[#8583F3]' />
                </div>
                <div className='flex flex-col items-start'>
                    <h1 className='textgrad monts text-[12px'>1. Select Assets:</h1>
                    <p className='monts text-sm'>Choose your assets and amounts</p>
                </div>
                <div className='flex flex-col items-start'>
                    <h1 className='textgrad monts text-[12px'>2. Choose Networks:</h1>
                    <p className='monts text-sm'> Select blockchain networks.</p>
                </div>
                <div className='flex flex-col items-start'>
                    <h1 className='textgrad monts text-[12px'>3. Aggregator Scan:</h1>
                    <p className='monts text-sm'>Sicrix finds best prices across DEXs.</p>
                </div>
                <div className='flex flex-col items-start'>
                    <h1 className='textgrad monts text-[12px'>4. Optimal Trade:</h1>
                    <p className='monts text-sm'>Execute trade with minimal slippage</p>
                </div>
                <div className='flex flex-col items-start'>
                    <h1 className='textgrad monts text-[12px'>5. Transaction Confirmation:</h1>
                    <p className='monts text-sm'>Confirm and monitor the transaction.</p>
                </div>
                <div className='flex flex-col items-start'>
                    <h1 className='textgrad monts text-[12px'>6. Completed Swap:</h1>
                    <p className='monts text-sm'>Enjoy seamless swaps.</p>
                </div>
            </div>
            <img data-aos="fade-up" data-aos-duration="1500" src={require('../../assets/how.png')} alt="How it works" className='w-[90%] md:w-[350px] object-contain' />
        </div>
        <a href="https://app.sicrix.network/" className='bgblue px-3 md:px-5 py-2.5 md:py-3 monts rounded-xl text-lg text-black bluegrad font-bold max-w-[400px] mx-auto w-[80%] text-center'>Launch App</a>
    </div>
  )
}
