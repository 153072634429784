import React, { useState } from 'react'

export default function Navbar() {
    const [openMenu, setOpenMenu] = useState(false);
    const [showSocials, setShowSocials] = useState(false);
  return (
    <div className="stickyNav p-3 md:p-5">
        <div className={`${openMenu ? 'rounded-lg' : 'rounded-full'} p-2 px-4 bg-[#141825] max-w-[1200px] mx-auto`}>
            <div className='flex items-center justify-between monts'>
                <img src={require('../../assets/logo.png')} alt="logo" className='w-[120px] md:w-[130px] object-contain' />
                <div className='items-center justify-center gap-5 text-gray-300 hidden md:flex text-xs'>
                    <a href='#'>Home</a>
                    <a href='#'>$SRX</a>
                    <div className='relative'>
                        <div onMouseEnter={() => setShowSocials(true)} onMouseLeave={() => setShowSocials(false)} className='flex-center gap-1 cursor-pointer'>
                            Community <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                            </svg>
                        </div>
                        <div className={`${showSocials ? 'flex' : 'hidden'} flex-col absolute top-5 bg-[#141825] p-5 gap-2 rounded-md -left-2`}>
                            <a href='https://x.com/sicrixNetwork'>Twitter</a>
                            <a href='#'>Threads</a>
                            <a href='https://www.facebook.com/sicrix'>Facebook</a>
                            <a href='https://medium.com/@SicrixNetwork'>Medium</a>
                            <a href='https://www.reddit.com/user/SicrixNetwork'>Reddit</a>
                            <a href='https://www.reddit.com/user/SicrixNetwork'>Telegram</a>
                        </div>
                    </div>
                    <a href='#'>Roadmap</a>
                    <a href='https://doc.sicrix.network/'>Document</a>
                </div>
                <div className='flex-center gap-1 md:gap-4'>
                      <div className='flex-center gap-2'>
                          <a href='https://app.sicrix.network/' className='bgblue px-3 md:px-5 py-[6px] rounded-lg tracking-wider text-xs text-black bluegrad font-bold hidden md:block'>Launch App</a>
                          <div className='flex-center'>
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-[#83a6f3]">
                                  <path strokeLinecap="round" strokeLinejoin="round" d="M12 21a9.004 9.004 0 0 0 8.716-6.747M12 21a9.004 9.004 0 0 1-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 0 1 7.843 4.582M12 3a8.997 8.997 0 0 0-7.843 4.582m15.686 0A11.953 11.953 0 0 1 12 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0 1 21 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0 1 12 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 0 1 3 12c0-1.605.42-3.113 1.157-4.418" />
                              </svg>
                              <p className='text-[#83a6f3]'>EN</p>
                          </div>
                    </div>
                    <div className='md:hidden'>
                        <svg onClick={() => setOpenMenu(true)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor"  className={`w-7 h-10 text-[#83a6f3] ${openMenu && 'hidden'}`}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                        </svg>
                        <svg onClick={() => setOpenMenu(false)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`w-7 h-10 text-[#83a6f3] ${!openMenu && 'hidden'}`}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                        </svg>
                    </div>
                </div>
            </div>
            <div className={`${openMenu ? 'flex' : 'hidden'} items-center justify-center gap-5 text-gray-300 flex-col text-sm my-5`}>
                    <a href='#'>Home</a>
                    <a href='#'>$SRX</a>
                    <div className='flex-center gap-2 flex-col'>
                        <div onClick={() => setShowSocials(!showSocials)} className='flex-center gap-1 cursor-pointer'>
                            Community <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                            </svg>
                        </div>
                        <div className={`${showSocials ? 'flex' : 'hidden'} flex-col gap-2 rounded-md -left-2 text-center text-xs`}>
                            <a href='https://x.com/sicrixNetwork'>Twitter</a>
                            <a href='#'>Threads</a>
                            <a href='https://www.facebook.com/sicrix'>Facebook</a>
                            <a href='https://medium.com/@SicrixNetwork'>Medium</a>
                            <a href='https://www.reddit.com/user/SicrixNetwork'>Reddit</a>
                            <a href='https://www.reddit.com/user/SicrixNetwork'>Telegram</a>
                        </div>
                    </div>
                    <a href='#'>Roadmap</a>
                    <a href='https://doc.sicrix.network/'>Document</a>
                    <a href='https://app.sicrix.network/' className='bgblue w-[60%] text-center py-2 rounded-full text-xs text-black bluegrad font-bold'>Launch App</a>
            </div>
        </div>
    </div>
  )
}
