import React from 'react'

export default function Bridges() {
  return (
    <div className='flex-center flex-col mt-20 md:mt-24 gap-10 w-[90%] mx-auto'>
        <h1 className='hidden md:block yapari monts font-extrabold text-[2rem] text-center mx-10 md:mx-auto'>
            Supported Bridges
        </h1>
        <h1 className='md:hidden yapari text-4xl text-center mx-10 md:mx-auto'>
            <span className='text-xl textblue'>Supported </span><br />
            Bridges
        </h1>
        <div className='flex items-center justify-center gap-10 flex-wrap max-w-[900px] rounded-xl border-[#8583f345] bg-[#83a6f30a] mx-auto w-full border p-5'>
            <img data-aos="fade-up" data-aos-duration="1500" src={require('../../assets/bridge/1.png')} alt="blockchain logo" className='w-[40px] md:w-[45px]' />
            <img data-aos="fade-up" data-aos-duration="1500" src={require('../../assets/bridge/2.png')} alt="blockchain logo" className='w-[40px] md:w-[45px]' />
            <img data-aos="fade-up" data-aos-duration="1500" src={require('../../assets/bridge/3.png')} alt="blockchain logo" className='w-[40px] md:w-[45px]' />
            <img data-aos="fade-up" data-aos-duration="1500" src={require('../../assets/bridge/4.png')} alt="blockchain logo" className='w-[40px] md:w-[45px]' />
            <img data-aos="fade-up" data-aos-duration="1500" src={require('../../assets/bridge/5.png')} alt="blockchain logo" className='w-[40px] md:w-[45px]' />
            <img data-aos="fade-up" data-aos-duration="1500" src={require('../../assets/bridge/7.png')} alt="blockchain logo" className='w-[40px] md:w-[45px]' />
            <img data-aos="fade-up" data-aos-duration="1500" src={require('../../assets/bridge/8.png')} alt="blockchain logo" className='w-[40px] md:w-[45px]' />
            <img data-aos="fade-up" data-aos-duration="1500" src={require('../../assets/bridge/9.png')} alt="blockchain logo" className='w-[40px] md:w-[45px]' />
            <img data-aos="fade-up" data-aos-duration="1500" src={require('../../assets/bridge/10.png')} alt="blockchain logo" className='w-[40px] md:w-[45px]' />
            <img data-aos="fade-up" data-aos-duration="1500" src={require('../../assets/bridge/11.png')} alt="blockchain logo" className='w-[40px] md:w-[45px]' />
            <img data-aos="fade-up" data-aos-duration="1500" src={require('../../assets/bridge/12.png')} alt="blockchain logo" className='w-[40px] md:w-[45px]' />
            <img data-aos="fade-up" data-aos-duration="1500" src={require('../../assets/bridge/13.png')} alt="blockchain logo" className='w-[40px] md:w-[45px]' />
            <img data-aos="fade-up" data-aos-duration="1500" src={require('../../assets/bridge/14.png')} alt="blockchain logo" className='w-[40px] md:w-[45px]' />
            <img data-aos="fade-up" data-aos-duration="1500" src={require('../../assets/bridge/15.png')} alt="blockchain logo" className='w-[40px] md:w-[45px]' />
            <img data-aos="fade-up" data-aos-duration="1500" src={require('../../assets/bridge/16.png')} alt="blockchain logo" className='w-[40px] md:w-[45px]' />
            <img data-aos="fade-up" data-aos-duration="1500" src={require('../../assets/bridge/17.png')} alt="blockchain logo" className='w-[40px] md:w-[45px]' />
            <img data-aos="fade-up" data-aos-duration="1500" src={require('../../assets/bridge/18.png')} alt="blockchain logo" className='w-[40px] md:w-[45px]' />
            <img data-aos="fade-up" data-aos-duration="1500" src={require('../../assets/bridge/19.png')} alt="blockchain logo" className='w-[40px] md:w-[45px]' />
            <img data-aos="fade-up" data-aos-duration="1500" src={require('../../assets/bridge/20.png')} alt="blockchain logo" className='w-[40px] md:w-[45px]' />
            <img data-aos="fade-up" data-aos-duration="1500" src={require('../../assets/chains/plus.png')} alt="blockchain logo" className='w-[40px] md:w-[45px]' />
        </div>
    </div>
  )
}