import React, { useEffect, useState } from 'react';
import { ArrowDownOutlined, DownOutlined, SettingOutlined } from '@ant-design/icons';
import { Input, Modal, Popover, Radio, message } from 'antd';
import axios from 'axios';
import { useSendTransaction, useWaitForTransaction } from 'wagmi';
import { url } from '../../url';

function Swap({ isConnected, address, defaultTokenOne, defaultTokenTwo, setOpenList, openList }) {

  const [messageApi, contextHolder] = message.useMessage();

  const [slippage, setSlippage] = useState(2.5);
  const [tokenOneAmount, setTokenOneAmount] = useState(null);
  const [tokenTwoAmount, setTokenTwoAmount] = useState(null);
  const [tokenOne, setTokenOne] = useState(defaultTokenOne);
  const [tokenTwo, setTokenTwo] = useState(defaultTokenTwo);
  const [isOpen, setIsOpen] = useState(false);
  const [prices, setPrices] = useState(null);
  const [txDetails, setTxDetails] = useState({
    to: null,
    data: null,
    value: null
  });

  const { data, sendTransaction } = useSendTransaction({
    request: {
      from: address,
      to: String(txDetails.to),
      data: String(txDetails.data),
      value: String(txDetails.value),
    }
  })

  const { isLoading, isSuccess } = useWaitForTransaction({
    hash: data?.hash
  });

  const handleSlippageChange = e => {
    setSlippage(e.target.value);
  }

  const changeAmount = e => {
    setTokenOneAmount(e.target.value);
    if(e.target.value && prices){
      setTokenTwoAmount((e.target.value * prices.ratio).toFixed(2));
    } else {
      setTokenTwoAmount(null);
    }
  }

  const switchTokens = () => {
    setPrices(null);
    setTokenOneAmount(null);
    setTokenTwoAmount(null);
    const one = tokenOne;
    const two = tokenTwo;
    setTokenOne(two);
    setTokenTwo(one);
    fetchPrices(two.address, one.address);
  }

  const openModal = asset => {
    setPrices(null);
    setTokenOneAmount(0);
    setTokenTwoAmount(0);
    setOpenList(asset);
  }

  const fetchPrices = async (one, two) => {
    const res = await axios.get(`${url}/tokenprice?addressOne=${one}&addressTwo=${two}`);
    setPrices(res.data);
  }

  const fetchDexSwap = async () => {

    const allowance = await axios.get(`${url}/allowance/${tokenOne.address}/${address}`);

    if(allowance.data.allowance == "0"){
      const approve = await axios.get(`${url}/approve/${address}`);
      setTxDetails(approve.data);
      return;
    }

    const tx = await axios.get(`${url}/swap`, {
      params: {
        src: tokenOne.address,
        dst: tokenTwo.address,
        from: address,
        slippage,
        amount: tokenOneAmount.padEnd(tokenOne.decimals+tokenOneAmount.length, '0'),
      }
    })

    let decimals = Number(`1E${tokenTwoAmount.decimals}`);
    setTokenTwoAmount((Number(tx.data.toTokenAmount)/decimals).toFixed(2));

    setTxDetails(tx.data);
  }

  useEffect(() => {
    fetchPrices(tokenOne.address, tokenTwo.address);
  }, [])

  useEffect(() => {
    if(txDetails.to && isConnected){
      sendTransaction();
    }
  }, [txDetails])

  useEffect(() => {
    messageApi.destroy();
    if(isLoading){
      messageApi.open({
        type: "loading",
        content: 'Transaction is Pending...',
        duration: 0,
      })
    }
  }, [isLoading])

  useEffect(() => {
    messageApi.destroy();
    if(isSuccess){
      messageApi.open({
        type: 'success',
        content: 'Transaction Successful',
        duration: 1.5,
      })
    } else if(txDetails.to){
      messageApi.open({
        type: 'error',
        content: 'Transaction Failed',
        duration: 1.50,
      })
    }
  }, [isSuccess])

  const settings = (
    <>
      <div>Slippage Tolerance</div>
      <div>
        <Radio.Group value={slippage} onChange={handleSlippageChange}>
          <Radio.Button value={0.5}>0.5%</Radio.Button>
          <Radio.Button value={2.5}>2.5%</Radio.Button>
          <Radio.Button value={5}>5.0%</Radio.Button>
        </Radio.Group>
      </div>
    </>
  )

  return (
    <>
    {contextHolder}
      <div className="tradeBox mt-20 md:mt-36 w-full max-w-[600px] mx-auto p-5 pb-10">
        <div className="tradeBoxHeader">
          <h4 className='text-2xl monts font-extrabold'>Cross Chain{!prices && <span className='text-xs tracking-wider'> (Loading. . .)</span>}</h4>
          <Popover
            content={settings}
            title="Settings"
            trigger='click'
            placement='bottomRight'
          >
            <SettingOutlined className='cog' />
          </Popover>
        </div>
        <div className='inputs mt-5 space-y-2 w-full'>
          <Input disabled={!prices} placeholder='0' type='number'  value={tokenOneAmount} onChange={changeAmount} />
          <Input placeholder='0' value={tokenTwoAmount} disabled={true} />
          <div className='switchButton' onClick={switchTokens}>
            <ArrowDownOutlined className='switchArrow' />
          </div>
          <div className="assetOne" onClick={() => openModal(1)}>
            <img src={tokenOne.logoURI} alt="cryptoTokenLogo" className='assetLogo' />
            {tokenOne.symbol}
            <DownOutlined />
          </div>
          <div className="assetTwo" onClick={() => openModal(2)}>
          <img src={tokenTwo.logoURI} alt="cryptoTokenLogo" className='assetLogo' />
            {tokenTwo.symbol}
            <DownOutlined />
          </div>
        </div>
        <div onClick={fetchDexSwap} className="swapButton monts" disabled={!tokenOneAmount || !isConnected}>Swap</div>
      </div>
    </>
  )
}

export default Swap