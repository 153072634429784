import React from 'react'

export default function Chains() {
  return (
    <div className='flex-center flex-col mt-20 md:mt-36 gap-10 w-[90%] mx-auto'>
        <h1 className='hidden md:block yapari monts font-extrabold text-[2rem] text-center mx-10 md:mx-auto'>
            Supported Blockchains
        </h1>
        <h1 className='md:hidden yapari text-3xl text-center mx-10 md:mx-auto'>
            <span className='text-xl textblue'>Supported </span><br />
            Blockchains
        </h1>
        <div className='flex items-center justify-center gap-10 flex-wrap max-w-[900px] rounded-xl border-[#8583f345] bg-[#83a6f30a] mx-auto w-full border p-5'>
            <img data-aos="fade-up" data-aos-duration="1500" src={require('../../assets/chains/20.png')} alt="blockchain logo" className='w-[40px] md:w-[45px]' />
            <img data-aos="fade-up" data-aos-duration="1500" src={require('../../assets/chains/1.png')} alt="blockchain logo" className='w-[40px] md:w-[45px]' />
            <img data-aos="fade-up" data-aos-duration="1500" src={require('../../assets/chains/2.png')} alt="blockchain logo" className='w-[40px] md:w-[45px]' />
            <img data-aos="fade-up" data-aos-duration="1500" src={require('../../assets/chains/3.png')} alt="blockchain logo" className='w-[40px] md:w-[45px]' />
            <img data-aos="fade-up" data-aos-duration="1500" src={require('../../assets/chains/4.png')} alt="blockchain logo" className='w-[40px] md:w-[45px]' />
            <img data-aos="fade-up" data-aos-duration="1500" src={require('../../assets/chains/5.png')} alt="blockchain logo" className='w-[40px] md:w-[45px]' />
            <img data-aos="fade-up" data-aos-duration="1500" src={require('../../assets/chains/7.png')} alt="blockchain logo" className='w-[40px] md:w-[45px]' />
            <img data-aos="fade-up" data-aos-duration="1500" src={require('../../assets/chains/8.png')} alt="blockchain logo" className='w-[40px] md:w-[45px]' />
            <img data-aos="fade-up" data-aos-duration="1500" src={require('../../assets/chains/9.png')} alt="blockchain logo" className='w-[40px] md:w-[45px]' />
            <img data-aos="fade-up" data-aos-duration="1500" src={require('../../assets/chains/10.png')} alt="blockchain logo" className='w-[40px] md:w-[45px]' />
            <img data-aos="fade-up" data-aos-duration="1500" src={require('../../assets/chains/11.png')} alt="blockchain logo" className='w-[40px] md:w-[45px]' />
            <img data-aos="fade-up" data-aos-duration="1500" src={require('../../assets/chains/12.png')} alt="blockchain logo" className='w-[40px] md:w-[45px]' />
            <img data-aos="fade-up" data-aos-duration="1500" src={require('../../assets/chains/13.png')} alt="blockchain logo" className='w-[40px] md:w-[45px]' />
            <img data-aos="fade-up" data-aos-duration="1500" src={require('../../assets/chains/14.png')} alt="blockchain logo" className='w-[40px] md:w-[45px]' />
            <img data-aos="fade-up" data-aos-duration="1500" src={require('../../assets/chains/15.png')} alt="blockchain logo" className='w-[40px] md:w-[45px]' />
            <img data-aos="fade-up" data-aos-duration="1500" src={require('../../assets/chains/16.png')} alt="blockchain logo" className='w-[40px] md:w-[45px]' />
            <img data-aos="fade-up" data-aos-duration="1500" src={require('../../assets/chains/17.png')} alt="blockchain logo" className='w-[40px] md:w-[45px]' />
            <img data-aos="fade-up" data-aos-duration="1500" src={require('../../assets/chains/18.png')} alt="blockchain logo" className='w-[40px] md:w-[45px]' />
            <img data-aos="fade-up" data-aos-duration="1500" src={require('../../assets/chains/19.png')} alt="blockchain logo" className='w-[40px] md:w-[45px]' />
            <img data-aos="fade-up" data-aos-duration="1500" src={require('../../assets/chains/plus.png')} alt="blockchain logo" className='w-[40px] md:w-[45px]' />
        </div>
    </div>
  )
}
