import Navbar from './Navbar'
import Hero from './Hero'
import Chains from './Chains'
import Bridges from './Bridges'
import Dex from './Dex'
import Cards from '../Cards'
import Footer from './Footer'
import Tokenomics from './Tokenomics'
import Roadmap from './Roadmap'
import Features from './Features'
import How from './How'
import Cookie from './Cookie'
import { useState } from 'react'

export default function HomePage() {
  const cookieState = window.localStorage.getItem('cookie');
  const [isCookie, setIsCookie] = useState(cookieState);
  return (
    <div className=''>
      <Navbar />
      <Hero />
      <Cards />
      <Features />
      <div className='flex-center flex-col radialgradleft pb-10'>
        <Chains />
        <Bridges />
        <Dex />
      </div>
      <div className='flex-center flex-col radialgradcenter pb-10'>
        <How />
        <Roadmap />
      </div>
      <Tokenomics />
      <Footer />
      {!isCookie && <Cookie setIsCookie={setIsCookie} />}      
    </div>
  )
}
