import React from 'react'

function Header({ connect, isConnected, address }) {
  return (
    <div className='w-full max-w-[1100px] mx-auto m-5 flex items-center justify-between'>
      <a href="https://www.sicrix.network/">
        <img src={require('../assets/logo.png')} alt="logo" className='w-[130px] md:w-[160px] object-contain' />
      </a>
      <div className='monts items-center justify-center gap-5 text-gray-300 hidden md:flex text-sm'>
        <p>Home</p>
        <p>$SRX</p>
        <p>Staking</p>
        <p>Community</p>
        <p>Roadmap</p>
        <p>Document</p>
      </div>
      <div className='monts connectButton text-xs md:text-sm' onClick={connect}>
        {isConnected ? (`${address.slice(0,4)}...${address.slice(38)}`) : 'Connect Wallet' }
      </div>
    </div>
  )
}

export default Header