import React from 'react'

export default function Features() {
  return (
    <div className='radialgradright'>
      <div className='mt-20 md:flex items-center justify-around gap-5 flex-wrap max-w-[1250px] mx-auto hidden'>

      <div className='w-full md:w-auto h-[80vh]'>
            <div data-aos="fade-up" data-aos-duration="1500" className='h-[500px] w-[80%] mx-auto md:w-[370px] px-8 flex items-center flex-col featuregrad justify-end border-[#89d7fb19] border-2 pb-10 rounded-xl'>
                <img src={require('../../assets/liq.gif')} alt="smart routing" className='w-[80%] object-contain mb-10' />
                <h1 className='monts font-extrabold text-2xl text-left w-full'>Liquidity Pools</h1>
                <p className='mt-3 text-justify text-sm font-thin'>Dive into diverse liquid pools for your favorite tokens, enhancing trading opportunities</p>
                <a href="https://doc.sicrix.network/sicrix/overview/liquidity-pools" className='rounded-md mt-5 text-xs bluegrad text-black px-5 py-1 monts font-bold flex-center self-start'>
                  Learn More
                </a>
            </div>
        </div>

        <div className='w-full md:w-auto h-[80vh]'>
            <div data-aos="fade-up" data-aos-duration="1500" className='h-[500px] w-[80%] mx-auto md:w-[370px] px-8 flex items-center flex-col featuregrad justify-end border-[#89d7fb19] border-2 pb-10 rounded-xl'>
                <img src={require('../../assets/non.gif')} alt="Non-Custodial" className='w-[80%] object-contain mb-10' />
                <h1 className='monts font-extrabold text-2xl text-left w-full'>Non-Custodial</h1>
                <p className='mt-3 text-justify text-sm font-thin'>Retain control of your funds with our non-custodial model, putting security frist.</p>
                <a href="https://doc.sicrix.network/sicrix/overview/non-custodial" className='rounded-md mt-5 text-xs bluegrad text-black px-5 py-1 monts font-bold flex-center self-start'>
                  Learn More
                </a>
            </div>
        </div>

        <div className='w-full md:w-auto h-[80vh]'>
            <div data-aos="fade-up" data-aos-duration="1500" className='h-[500px] w-[80%] mx-auto md:w-[370px] px-8 flex items-center flex-col featuregrad justify-end border-[#89d7fb19] border-2 pb-10 rounded-xl'>
                <img src={require('../../assets/smart.gif')} alt="smart routing" className='w-[80%] object-contain mb-10' />
                <h1 className='monts font-extrabold text-2xl text-left w-full'>Smart Routing</h1>
                <p className='mt-3 text-justify text-sm font-thin'>Our advanced algorithms find the best prices and routes for your trades, maximizing returns</p>
                <a href="https://doc.sicrix.network/sicrix/overview/smart-routing" className='rounded-md mt-5 text-xs bluegrad text-black px-5 py-1 monts font-bold flex-center self-start'>
                  Learn More
                </a>
            </div>
        </div>

      </div>
    </div>
  )
}
