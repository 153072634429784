import React from 'react'
import Hotpairs from './Hotpairs'

export default function Hero() {
  return (
    <div className='min-h-[70vh] relative radialgrad'>
        <div className='max-w-[1180px] min-h-[65vh] mx-auto relative mt-24 hidden md:flex flex-col items-center justify-between'>
            <div className='z-10'>
                <div className='flex items-center flex-col w-full monts font-extrabold'>
                    <h1 className='text-[3.7rem] h-[60px] opacity-80'>Optimal Trades</h1>
                    <p className='text-[4rem] fadetext'>Minimal Slippage</p>
                </div>
                <p className='font-light text-center text-gray-300 max-w-[550px]'>Trade assets seamlessly across chains using our advanced DEX and bridge aggregator. Optimize Tardes with minimized slippage.</p>
                <div className='flex justify-center gap-5 mt-5'>
                    <a href="https://app.sicrix.network/" className='bluegrad text-black nunito px-10 py-[6px] text-md rounded-xl'>Launch App</a>
                    <a href="https://doc.sicrix.network/" className='bordergrad text-white nunito px-10 py-[6px] text-md rounded-xl'>Document</a>
                </div>
            </div>
            <img src={require('../../assets/hero.gif')} alt="hero" draggable='false' className='h-[48vh] object-contain' />
        </div>
        <div className='w-screen mx-auto relative mt-32 mb-7 md:hidden space-y-2'>
            <div className='flex-center flex-col w-full monts'>
                <h1 className='textblue text-[2.7rem] h-[53px]'>Optimal Trades</h1>
                <p className='text-[2rem]'>Minimal Slippage</p>
            </div>
            <img src={require('../../assets/hero.gif')} alt="hero" className='w-screen mx-auto md:mt-0' />
            <p className='text-sm text-center nunito mx-10 text-gray-300 !mb-16'>Trade assets seamlessly across chains using our advanced DEX and bridge aggregator. Optimize Tardes with minimized slippage.</p>
            <div className='flex-center gap-5 monts'>
                <a href="https://app.sicrix.network/" className='bluegrad text-black nunito px-8 py-[6px] text-md rounded-xl'>Launch App</a>
                <a href="https://doc.sicrix.network/" className='bordergrad text-white nunito px-8 py-[6px] text-md rounded-xl'>Document</a>
            </div>
        </div>
        {/* <br /> */}
        <div className='w-full relative'>
            <h1 className='overflow-hidden absolute left-3 md:left-0 top-2 monts bg-reds-600 text-sm md:text-lg z-20 w-[93.5%] md:w-full md:border-y md:bg-[#8583f30e] border-[#83a6f310] py-3'> <span className='py-4 rounded-full px-3'></span> </h1>
        </div>
        <Hotpairs />
    </div>
  )
}
