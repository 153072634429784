import React, { useState } from 'react'
import Prices from './Prices';

export default function Tokenomics() {
    
    const [copy, setCopy] = useState(false);
    
    const address = "0x4c43Dfe081DF890d3EDC743f5B690C3376c3e391";
    
    const handleCopy = () => {
        navigator.clipboard.writeText(address)
        .then(() => {
        })
        .catch((error) => {
        window.alert('Unable to copy text to clipboard');
    });
        setCopy(true);
        setTimeout(() => {
            setCopy(false);
        }, [2000]);
    }

  return (
    <section id="tokenomics" className="md:pt-20 md:py-20 backers w-full radialgradbuttomright">
        <div className="max-w-[1150px] mx-auto w-full flex-center flex-col">
            <div className="flex items-center justify-between flex-col-reverse md:flex-row w-full">
                <div data-aos="fade-up" data-aos-duration="1500" className="relative overflow-x-auto sm:rounded-lg md:min-w-[450px]">
                <h1 className="monts font-extrabold text-3xl md:text-5xl text-left my-5 textgrad">Tokenomics</h1>
                    <table className="w-full text-sm text-left rtl:text-right text-gray-400">
                        <thead className="text-xs bg-transparent text-white">
                            <tr>
                                <th scope="col" className="pr-10 py-3 monts text-sm md:text-[16px] font-light">
                                    <p className="opacity-70 font-extralight">Token Name</p>
                                    <p className="opacity-70 font-extralight md:mt-2">Total Supply</p>
                                </th>
                                <th scope="col" className="pr-10 py-3 monts text-sm md:text-lg">
                                    <p className="monts font-extrabold">Sicrix</p>
                                    <p className="monts font-extrabold">1,000,000</p>
                                </th>
                                <th scope="col" className="py-3 monts text-sm md:text-lg">
                                    <p className="opacity-70 font-extralight">Percent</p>
                                    <p className="opacity-70 font-extralight">Allocated</p>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="odd:bg-[#83a6f30a] even:bg-transparent">
                                <th scope="row" className="px-6 py-4 font-medium whitespace-nowrap text-white">
                                    Presale
                                </th>
                                <td className="py-4">
                                    375,000
                                </td>
                                <td className="py-4">37.5%</td>
                            </tr>
                            <tr className="odd:bg-[#83a6f30a] even:bg-transparent">
                                <th scope="row" className="px-6 py-4 font-medium whitespace-nowrap text-white">
                                    Liquidity
                                </th>
                                <td className="py-4">
                                    159,885
                                </td>
                                <td className="py-4">15.9885%</td>
                            </tr>
                            <tr className="odd:bg-[#83a6f30a] even:bg-transparent">
                                <th scope="row" className="px-6 py-4 font-medium whitespace-nowrap text-white">
                                    Marketing
                                </th>
                                <td className="py-4">
                                    150,000
                                </td>
                                <td className="py-4">15%</td>
                            </tr>
                            <tr className="odd:bg-[#83a6f30a] even:bg-transparent">
                                <th scope="row" className="px-6 py-4 font-medium whitespace-nowrap text-white">
                                    Staking
                                </th>
                                <td className="py-4">
                                    125,000
                                </td>
                                <td className="py-4">12.5%</td>
                            </tr>
                            <tr className="odd:bg-[#83a6f30a] even:bg-transparent">
                                <th scope="row" className="px-6 py-4 font-medium whitespace-nowrap text-white">
                                    Partners
                                </th>
                                <td className="py-4">
                                    40,000
                                </td>
                                <td className="py-4">4%</td>
                            </tr>
                            <tr className="odd:bg-[#83a6f30a] even:bg-transparent">
                                <th scope="row" className="px-6 py-4 font-medium whitespace-nowrap text-white">
                                    CEX
                                </th>
                                <td className="py-4">
                                    100,000
                                </td>
                                <td className="py-4">10%</td>
                            </tr>
                            <tr className="odd:bg-[#83a6f30a] even:bg-transparent">
                                <th scope="row" className="px-6 py-4 font-medium whitespace-nowrap text-white">
                                    Team
                                </th>
                                <td className="py-4">
                                    50,000
                                </td>
                                <td className="py-4">5%</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="flex-center flex-col w-[95%] md:w-auto relative mt-5 md:mt-auto mb-10 md:mb-auto">
                    <img data-aos="fade-up" data-aos-duration="1500" src={require('../../assets/tokenomics.gif')} alt="Podium" className="w-[90%] max-w-[550px]" />
                    {/* <p className="text-gray-400 text-[16px] md:text-xl nunito mt-5">Transaction Tax: 2% </p> */}
                    <div className="flex-center gap-2">
                        <p className="textblue sm:text-sm nunito contract_add text-[10px]">{address}</p>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" onClick={handleCopy} className={`w-5 h-5 md:w-6 md:h-6 mb-1 copyBtn cursor-pointer ${copy ? 'textblue' : 'text-[#3a86a9]'}`}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 7.5V6.108c0-1.135.845-2.098 1.976-2.192.373-.03.748-.057 1.123-.08M15.75 18H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08M15.75 18.75v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5A3.375 3.375 0 006.375 7.5H5.25m11.9-3.664A2.251 2.251 0 0015 2.25h-1.5a2.251 2.251 0 00-2.15 1.586m5.8 0c.065.21.1.433.1.664v.75h-6V4.5c0-.231.035-.454.1-.664M6.75 7.5H4.875c-.621 0-1.125.504-1.125 1.125v12c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V16.5a9 9 0 00-9-9z" />
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}