import React from 'react'
import "./Cards.css"

export default function Cards() {
    return (
        <div className="hero-bg md:hidden">
            <div className="container">
                <ul id="cards">
                    <li className="card" id="card1">
                        <div className="card-body">
                            <div className='w-full md:w-auto rounded-3xl'>
                                <div className='h-[450px] w-[93%] mx-auto px-8 flex items-center flex-col featuregrad justify-end border-[#89d7fb19] border-2 pb-10 rounded-xl'>
                                    <img src={require('../assets/liq.gif')} alt="smart routing" className='w-[80%] object-contain mb-10' />
                                    <h1 className='monts font-extrabold text-2xl text-left w-full'>Liquidity Pools</h1>
                                    <p className='mt-3 text-justify text-sm font-thin'>Dive into diverse liquid pools for your favorite tokens, enhancing trading opportunities</p>
                                    <a href="https://doc.sicrix.network/sicrix/overview/liquidity-pools" className='rounded-md mt-5 text-xs bluegrad text-black px-5 py-1 monts font-bold flex-center self-start'>
                                        Learn More
                                    </a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li className="card" id="card2">
                        <div className="card-body">
                            <div className='w-full md:w-auto rounded-3xl'>
                                <div className='h-[450px] w-[96%] mx-auto px-8 flex items-center flex-col featuregrad justify-end border-[#89d7fb19] border-2 pb-10 rounded-xl'>
                                    <img src={require('../assets/non.gif')} alt="Non-Custodial" className='w-[80%] object-contain mb-10' />
                                    <h1 className='monts font-extrabold text-2xl text-left w-full'>Non-Custodial</h1>
                                    <p className='mt-3 text-justify text-sm font-thin'>Retain control of your funds with our non-custodial model, putting security frist.</p>
                                    <a href="https://doc.sicrix.network/sicrix/overview/non-custodial" className='rounded-md mt-5 text-xs bluegrad text-black px-5 py-1 monts font-bold flex-center self-start'>
                                        Learn More
                                    </a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li className="card" id="card3">
                        <div className="card-body">
                        <div className='w-full md:w-auto rounded-3xl'>
                                <div className='h-[450px] w-[100%] mx-auto px-8 flex items-center flex-col featuregrad justify-end border-[#89d7fb19] border-2 pb-10 rounded-xl'>
                                    <img src={require('../assets/smart.gif')} alt="Non-Custodial" className='w-[80%] object-contain mb-10' />
                                    <h1 className='monts font-extrabold text-2xl text-left w-full'>Smart Routing</h1>
                                    <p className='mt-3 text-justify text-sm font-thin'>Our advanced algorithms find the best prices and routes for your trades, maximizing returns</p>
                                    <a href="https://doc.sicrix.network/sicrix/overview/smart-routing" className='rounded-md mt-5 text-xs bluegrad text-black px-5 py-1 monts font-bold flex-center self-start'>
                                        Learn More
                                    </a>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    )
}
