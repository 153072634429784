import React from 'react'

export default function Hotpairs() {
    return (
        <div className='relative'>
            {/* HOT PRESALES */}
            <div className="hotcontainer">
                <div className="scroll-parent">
                    <div className="scroll-element primary -z-10">

                        {/* ------- FIRST SECTION -------- */}

                        {/* ETH - SRX */}
                        <div className='flex items-center gap-2'>
                            <img src={require('../../assets/chains/2.png')} alt="btc" className='h-7 object-contain' />
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 textblue">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M7.5 21 3 16.5m0 0L7.5 12M3 16.5h13.5m0-13.5L21 7.5m0 0L16.5 12M21 7.5H7.5" />
                            </svg>
                            <img src={require('../../assets/chains/10.png')} alt="btc" className='h-7 object-contain' />
                        </div>
                        {/* BTC TO ETH */}
                        <div className='flex items-center gap-2'>
                            <img src={require('../../assets/chains/20.png')} alt="btc" className='h-7 object-contain' />
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 textblue">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M7.5 21 3 16.5m0 0L7.5 12M3 16.5h13.5m0-13.5L21 7.5m0 0L16.5 12M21 7.5H7.5" />
                            </svg>
                            <img src={require('../../assets/chains/2.png')} alt="btc" className='h-7 object-contain' />
                        </div>
                        {/* USDC - SHIBA */}
                        <div className='flex items-center gap-2'>
                            <img src={require('../../assets/chains/4.png')} alt="btc" className='h-7 object-contain' />
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 textblue">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M7.5 21 3 16.5m0 0L7.5 12M3 16.5h13.5m0-13.5L21 7.5m0 0L16.5 12M21 7.5H7.5" />
                            </svg>
                            <img src={require('../../assets/chains/5.png')} alt="btc" className='h-7 object-contain' />
                        </div>
                        {/* USDT - MATIC */}
                        <div className='flex items-center gap-2'>
                            <img src={require('../../assets/chains/11.png')} alt="btc" className='h-7 object-contain' />
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 textblue">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M7.5 21 3 16.5m0 0L7.5 12M3 16.5h13.5m0-13.5L21 7.5m0 0L16.5 12M21 7.5H7.5" />
                            </svg>
                            <img src={require('../../assets/chains/12.png')} alt="btc" className='h-7 object-contain' />
                        </div>
                        {/* UNI - MKR */}
                        <div className='flex items-center gap-2'>
                            <img src={require('../../assets/chains/18.png')} alt="btc" className='h-7 object-contain' />
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 textblue">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M7.5 21 3 16.5m0 0L7.5 12M3 16.5h13.5m0-13.5L21 7.5m0 0L16.5 12M21 7.5H7.5" />
                            </svg>
                            <img src={require('../../assets/chains/19.png')} alt="btc" className='h-7 object-contain' />
                        </div>

                        {/*  ----------- SECOND SECTION -----------  */}
                        {/* ETH - SRX */}
                        <div className='flex items-center gap-2'>
                            <img src={require('../../assets/chains/2.png')} alt="btc" className='h-7 object-contain' />
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 textblue">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M7.5 21 3 16.5m0 0L7.5 12M3 16.5h13.5m0-13.5L21 7.5m0 0L16.5 12M21 7.5H7.5" />
                            </svg>
                            <img src={require('../../assets/chains/10.png')} alt="btc" className='h-7 object-contain' />
                        </div>
                        {/* BTC TO ETH */}
                        <div className='flex items-center gap-2'>
                            <img src={require('../../assets/chains/20.png')} alt="btc" className='h-7 object-contain' />
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 textblue">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M7.5 21 3 16.5m0 0L7.5 12M3 16.5h13.5m0-13.5L21 7.5m0 0L16.5 12M21 7.5H7.5" />
                            </svg>
                            <img src={require('../../assets/chains/2.png')} alt="btc" className='h-7 object-contain' />
                        </div>
                        {/* USDC - SHIBA */}
                        <div className='flex items-center gap-2'>
                            <img src={require('../../assets/chains/4.png')} alt="btc" className='h-7 object-contain' />
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 textblue">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M7.5 21 3 16.5m0 0L7.5 12M3 16.5h13.5m0-13.5L21 7.5m0 0L16.5 12M21 7.5H7.5" />
                            </svg>
                            <img src={require('../../assets/chains/5.png')} alt="btc" className='h-7 object-contain' />
                        </div>
                        {/* USDT - MATIC */}
                        <div className='flex items-center gap-2'>
                            <img src={require('../../assets/chains/11.png')} alt="btc" className='h-7 object-contain' />
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 textblue">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M7.5 21 3 16.5m0 0L7.5 12M3 16.5h13.5m0-13.5L21 7.5m0 0L16.5 12M21 7.5H7.5" />
                            </svg>
                            <img src={require('../../assets/chains/12.png')} alt="btc" className='h-7 object-contain' />
                        </div>
                        {/* UNI - MKR */}
                        <div className='flex items-center gap-2'>
                            <img src={require('../../assets/chains/18.png')} alt="btc" className='h-7 object-contain' />
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 textblue">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M7.5 21 3 16.5m0 0L7.5 12M3 16.5h13.5m0-13.5L21 7.5m0 0L16.5 12M21 7.5H7.5" />
                            </svg>
                            <img src={require('../../assets/chains/19.png')} alt="btc" className='h-7 object-contain' />
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}
