import React from 'react'

export default function Cookie({ setIsCookie }) {
    const handleCookie = () => {
        window.localStorage.setItem('cookie', 'true');
        setIsCookie(true);
    }
    return (
        <div className='bluegrads bg-white text-black p-3 md:p-5 fixed bottom-0 w-full'>
            <div className='flex flex-col md:flex-row items-center justify-between max-w-[1200px] mx-auto gap-4'>
                <p className='text-xs md:text-sm nunito'>Cookies on our website is helpful in, improving your experience, analyzing traffic, and supporting analytics. <br /> Learn more in our <a className='underline text-xs' href="https://doc.sicrix.network/legal/privacy-policy">Privacy Policy</a></p>
                <div className='flex items-center justify-start w-full md:w-auto md:justify-end gap-5 nunito text-sm'>
                    <p onClick={handleCookie} className='cursor-pointer border-black border px-6 py-1'>Disable All</p>
                    <p onClick={handleCookie} className='cursor-pointer bgblue px-6 py-1.5'>Accept All</p>
                </div>
            </div>
        </div>
    )
}
