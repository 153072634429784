import React, { useState } from 'react'

export default function Roadmap() {
    const [item, setItem] = useState(0);
    const [progress, setProgress] = useState(true);
    return (
        <section id="roadmap" className="md:mt-32 mt-20 md:my-20 max-w-[1200px] mx-auto w-full flex-center flex-col md rd-general">
            <h1 className='monts font-extrabold text-[3rem] text-center mx-10 md:mx-auto'>Roadmap</h1>
            <div className='w-full max-w-[1150px] mx-auto'>
                <div className='mt-10 flex flex-col md:flex-row items-start justify-between w-full'>

                    {/* LINE SECTION MOBILE */}
                    <div className='relative flex-1 p-5 mb-10 w-[90%] mx-auto md:hidden'>
                        <div className='border-b w-full border-[#8583f386] flex items-start relative gap-2'>
                            <div  onClick={() => setProgress(true)} className='w-[32px] h-[32px] border-2 border-[#8583f386] rounded-lg absolute -top-[16px] left-[40px] bg-[#141825] flex-center'>
                                <div className='w-[20px] h-[20px] rounded-md bg-[#8583F3]'  />
                            </div>
                        </div>
                        <h1 onClick={() => setProgress(true)} className='text-xl nunito mt-5 text-[#83a6f3]'>In Progress</h1>
                        <div onClick={() => setProgress(false)} className={`w-[30px] h-[30px] border-2 border-[#8583f345] rounded-lg absolute right-[40px] top-[6px] bg-[${progress ? '#141825' : '#8583F3'}] flex-center`}>
                                {/* <div className='w-[10px] h-[10px] rounded-sm bg-[#8583F3]'  /> */}
                                <div className={`w-[10px] h-[10px] rounded-sm bg-[${progress ? '#8583F3' : '#141825'}]`}  />
                        </div>
                        <h1 onClick={() => setProgress(false)} className='text-xl nunito absolute right-[10px] top-[40px] text-[#83a6f3]'>Upcoming</h1>
                    </div>
                    
                    {/* LINE SECTION */}
                    <div className='relative flex-1 mt-10 hidden md:block'>
                        <div className='border-l h-[150px] border-[#8583f386] flex items-start relative gap-2'>
                            <div onClick={() => setProgress(true)} className='w-[32px] h-[32px] border-2 border-[#8583f386] rounded-lg absolute -left-[16px] bg-[#141825] flex-center cursor-pointer'>
                                <div className={`w-[20px] h-[20px] rounded-md bg-[${progress && '#8583F3'}]`}  />
                            </div>
                            <h1 onClick={() => setProgress(true)} className='text-2xl nunito ml-7 text-[#83a6f3]'>In Progress</h1>
                        </div>
                        <div onClick={() => setProgress(false)} className={`w-[30px] h-[30px] border-2 border-[#8583f345] rounded-lg absolute -left-[15px] bg-[${progress ? '#141825' : '#8583F3'}] flex-center cursor-pointer`}>
                                <div className={`w-[10px] h-[10px] rounded-sm bg-[${progress ? '#8583F3' : '#141825'}]`}  />
                        </div>
                        <h1 onClick={() => setProgress(false)} className='text-2xl nunito ml-7 text-[#83a6f3]'>Upcoming</h1>
                    </div>

                    {/* BOX SECTION */}
                    <div className='max-w-[900px] rounded-xl border-[#8583f345] bg-[#83a6f30a] mx-auto w-[92%] border py-10 px-7 flex md:items-start justify-between flex-wrap gap-10'>
                        
                        <div className={`${progress ? 'flex' : 'hidden'} items-start justify-between w-full md:w-[400px] relative flex-col`}>
                            <div className='flex-center gap-2'>
                                <div className='w-[25px] h-[25px] border-2 border-[#8583f345] rounded-lg bg-[#141825] flex-center'>
                                        <div className='w-[10px] h-[10px] rounded-lg bg-[#8583F3]'  />
                                </div>
                                <h1 className='text-sm nunito'>Bridge and DEX Aggregator Development</h1>
                            </div>
                            <div className={`ml-3 mt-3 text-xs nunito text-gray-300 ${item === 1 ? '' : 'hidden'}`}>
                                <p>Initiate the development of the Sicrix Bridge, enabling seamless asset transfers across different chains.</p>
                                <p className='mt-2'>Introduce the Sicrix DEX Aggregator, providing users with a comprehensive trading experience across decentralized exchanges.</p>
                            </div>
                            <svg onClick={() => item === 1 ? setItem(0) : setItem(1)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 text-[#8583F3] opacity-50 cursor-pointer absolute right-0 top-0">
                                <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                            </svg>
                        </div>
                        
                        <div className={`${progress ? 'flex' : 'hidden'} items-start justify-between w-full md:w-[400px] relative flex-col`}>
                            <div className='flex-center gap-2'>
                                <div className='w-[25px] h-[25px] border-2 border-[#8583f345] rounded-lg bg-[#141825] flex-center'>
                                        <div className='w-[10px] h-[10px] rounded-lg bg-[#8583F3]'  />
                                </div>
                                <h1 className='text-sm nunito'>Presale and Token Distribution</h1>
                            </div>
                            <div className={`ml-3 mt-3 text-xs nunito text-gray-300 ${item === 2 ? '' : 'hidden'}`}>
                                <p>Plan and execute a strategic presale event to ensure fair and widespread token distribution.</p>
                                <p className='mt-2'>Utilize presale funds to support further development and community-building efforts.</p>
                            </div>
                            <svg onClick={() => item === 2 ? setItem(0) : setItem(2)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 text-[#8583F3] opacity-50 cursor-pointer absolute right-0 top-0">
                                <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                            </svg>
                        </div>
                        
                        <div className={`${progress ? 'flex' : 'hidden'} items-start justify-between w-full md:w-[400px] relative flex-col`}>
                            <div className='flex-center gap-2'>
                                <div className='w-[25px] h-[25px] border-2 border-[#8583f345] rounded-lg bg-[#141825] flex-center'>
                                    <div className='w-[10px] h-[10px] rounded-lg bg-[#8583F3]' />
                                </div>
                                <h1 className='text-sm nunito'>Staking Mechanism</h1>
                            </div>
                            <div className={`ml-3 mt-3 text-xs nunito text-gray-300 ${item === 3 ? '' : 'hidden'}`}>
                                <p>Introduce a robust staking mechanism to incentivize long-term engagement.</p>
                                <p className='mt-2'>Launch attractive staking pools to encourage token holders to participate actively.</p>
                            </div>
                            <svg onClick={() => item === 3 ? setItem(0) : setItem(3)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 text-[#8583F3] opacity-50 cursor-pointer absolute right-0 top-0">
                                <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                            </svg>
                        </div>
                        
                        <div className={`${progress ? 'flex' : 'hidden'} items-start justify-between w-full md:w-[400px] relative flex-col`}>
                            <div className='flex-center gap-2'>
                                <div className='w-[25px] h-[25px] border-2 border-[#8583f345] rounded-lg bg-[#141825] flex-center'>
                                        <div className='w-[10px] h-[10px] rounded-lg bg-[#8583F3]'  />
                                </div>
                                <h1 className='text-sm nunito'>DAO (Decentralized Autonomous Organization)</h1>
                            </div>
                            <div className={`ml-3 mt-3 text-xs nunito text-gray-300 ${item === 4 ? '' : 'hidden'}`}>
                                <p>Establish the Sicrix DAO, empowering the community with decision-making authority</p>
                                <p className='mt-2'>Implement voting mechanisms to involve token holders in shaping the future of Sicrix.</p>
                            </div>
                            <svg onClick={() => item === 4 ? setItem(0) : setItem(4)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 text-[#8583F3] opacity-50 cursor-pointer absolute right-0 top-0">
                                <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                            </svg>
                        </div>
                        
                        <div className={`${progress ? 'flex' : 'hidden'} items-start justify-between w-full md:w-[400px] relative flex-col`}>
                            <div className='flex-center gap-2'>
                                <div className='w-[25px] h-[25px] border-2 border-[#8583f345] rounded-lg bg-[#141825] flex-center'>
                                        <div className='w-[10px] h-[10px] rounded-lg bg-[#8583F3]'  />
                                </div>
                                <h1 className='text-sm nunito'>Marketing and Community Engagement</h1>
                            </div>
                            <div className={`ml-3 mt-3 text-xs nunito text-gray-300 ${item === 5 ? '' : 'hidden'}`}>
                                <p>Develop and implement a comprehensive marketing strategy to enhance awareness.</p>
                                <p className='mt-2'>Engage with the community through events, AMAs, and strategic partnerships.</p>
                            </div>
                            <svg onClick={() => item === 5 ? setItem(0) : setItem(5)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 text-[#8583F3] opacity-50 cursor-pointer absolute right-0 top-0">
                                <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                            </svg>
                        </div>
                        
                        <div className={`${progress ? 'flex' : 'hidden'} items-start justify-between w-full md:w-[400px] relative flex-col`}>
                            <div className='flex-center gap-2'>
                                <div className='w-[25px] h-[25px] border-2 border-[#8583f345] rounded-lg bg-[#141825] flex-center'>
                                        <div className='w-[10px] h-[10px] rounded-lg bg-[#8583F3]'  />
                                </div>
                                <h1 className='text-sm nunito'>Continuous Development and Optimization</h1>
                            </div>
                            <div className={`ml-3 mt-3 text-xs nunito text-gray-300 ${item === 6 ? '' : 'hidden'}`}>
                                <p>Regularly update and optimize the Sicrix platform based on user feedback.</p>
                                <p className='mt-2'>Explore new features and integrations to stay competitive and user-centric.</p>
                            </div>
                            <svg onClick={() => item === 6 ? setItem(0) : setItem(6)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 text-[#8583F3] opacity-50 cursor-pointer absolute right-0 top-0">
                                <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                            </svg>
                        </div>
                        
                        <div className={`${progress ? 'flex' : 'hidden'} items-start justify-between w-full md:w-[400px] relative flex-col`}>
                            <div className='flex-center gap-2'>
                                <div className='w-[25px] h-[25px] border-2 border-[#8583f345] rounded-lg bg-[#141825] flex-center'>
                                        <div className='w-[10px] h-[10px] rounded-lg bg-[#8583F3]'  />
                                </div>
                                <h1 className='text-sm nunito'>Audit and Security Enhancements</h1>
                            </div>
                            <div className={`ml-3 mt-3 text-xs nunito text-gray-300 ${item === 7 ? '' : 'hidden'}`}>
                                <p>Conduct regular audits of smart contracts and platform infrastructure to prioritize security.</p>
                                <p className='mt-2'>Collaborate with reputable security firms to fortify the Sicrix platform against potential threats.</p>
                            </div>
                            <svg onClick={() => item === 7 ? setItem(0) : setItem(7)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 text-[#8583F3] opacity-50 cursor-pointer absolute right-0 top-0">
                                <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                            </svg>
                        </div>
                        
                        <div className={`${!progress ? 'flex' : 'hidden'} items-start justify-between w-full md:w-[400px] relative flex-col`}>
                            <div className='flex-center gap-2'>
                                <div className='w-[25px] h-[25px] border-2 border-[#8583f345] rounded-lg bg-[#141825] flex-center'>
                                        <div className='w-[10px] h-[10px] rounded-lg bg-[#8583F3]'  />
                                </div>
                                <h1 className='text-sm nunito'>Mobile App Integration</h1>
                            </div>
                            <div className={`ml-3 mt-3 text-xs nunito text-gray-300 ${item === 8 ? '' : 'hidden'}`}>
                                <p>Develop a user-friendly mobile app, extending accessibility and convenience for users on the go.</p>
                            </div>
                            <svg onClick={() => item === 8 ? setItem(0) : setItem(8)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 text-[#8583F3] opacity-50 cursor-pointer absolute right-0 top-0">
                                <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                            </svg>
                        </div>
                        
                        <div className={`${!progress ? 'flex' : 'hidden'} items-start justify-between w-full md:w-[400px] relative flex-col`}>
                            <div className='flex-center gap-2'>
                                <div className='w-[25px] h-[25px] border-2 border-[#8583f345] rounded-lg bg-[#141825] flex-center'>
                                        <div className='w-[10px] h-[10px] rounded-lg bg-[#8583F3]'  />
                                </div>
                                <h1 className='text-sm nunito'>Global Expansion</h1>
                            </div>
                            <div className={`ml-3 mt-3 text-xs nunito text-gray-300 ${item === 9 ? '' : 'hidden'}`}>
                                <p>Explore partnerships and integrations to expand Sicrix's presence into new markets and blockchain networks.</p>
                            </div>
                            <svg onClick={() => item === 9 ? setItem(0) : setItem(9)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 text-[#8583F3] opacity-50 cursor-pointer absolute right-0 top-0">
                                <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                            </svg>
                        </div>
                        
                        <div className={`${!progress ? 'flex' : 'hidden'} items-start justify-between w-full md:w-[400px] relative flex-col`}>
                            <div className='flex-center gap-2'>
                                <div className='w-[25px] h-[25px] border-2 border-[#8583f345] rounded-lg bg-[#141825] flex-center'>
                                        <div className='w-[10px] h-[10px] rounded-lg bg-[#8583F3]'  />
                                </div>
                                <h1 className='text-sm nunito'>Research and Innovation</h1>
                            </div>
                            <div className={`ml-3 mt-3 text-xs nunito text-gray-300 ${item === 10 ? '' : 'hidden'}`}>
                                <p>Allocate resources to continuous research and development, staying abreast of technological advancements in the blockchain space.</p>
                            </div>
                            <svg onClick={() => item === 10 ? setItem(0) : setItem(10)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 text-[#8583F3] opacity-50 cursor-pointer absolute right-0 top-0">
                                <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                            </svg>
                        </div>

                    </div>

                </div>
            </div>
        </section>
    )
}
