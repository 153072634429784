import React, { useState } from 'react'
import Header from '../../components/Header'
import Swap from './Swap'
import tokenList from '../../tokenList.json';
import TokenList from './TokenList';

export default function Aggregator({ connect, isConnected, address }) {
    const [tokenOne, setTokenOne] = useState(tokenList[0]);
    const [tokenTwo, setTokenTwo] = useState(tokenList[1]);
    const [openList, setOpenList] = useState(0);
    const url = 'https://crypto.com/__assets/mkt-nav-footer/images/icons/socials';
    return (
        <div className="App p-3 md:p-5 flex items-center justify-center flex-col relative overflow-x-hidden">
            <Header connect={connect} isConnected={isConnected} address={address} />
            <div className="w-full">
                {
                    openList ?
                        <TokenList
                            setOpenList={setOpenList}
                            openList={openList}
                            setTokenOne={setTokenOne}
                            setTokenTwo={setTokenTwo}
                        /> :
                        <Swap
                            isConnected={isConnected}
                            address={address}
                            defaultTokenOne={tokenOne}
                            defaultTokenTwo={tokenTwo}
                            setOpenList={setOpenList}
                            openList={openList}
                        />
                }
            </div>
            <div className='mt-40 w-full'>
                <div className='flex items-center justify-between w-full max-w-[1200px] mx-auto'>
                    <div className='w-[30%] h-[1px] bg-[#89d7fb33] hidden md:block' />
                    <div className='flex items-center justify-center gap-5 w-full md:w-auto'>
                        <a href="https://x.com/sicrixNetwork">
                            <img src={`${url}/twitter.svg`} alt="logo" className='h-5 md:h-10 circle border border-gray-600' />
                        </a>
                        <a href="#">
                            <img src={`${url}/threads.svg`} alt="logo" className='h-5 md:h-10 circle border border-gray-600' />
                        </a>
                        <a href="https://www.facebook.com/sicrix">
                            <img src={`${url}/facebook.svg`} alt="logo" className='h-5 md:h-10 circle border border-gray-600' />
                        </a>
                        <a href="https://medium.com/@SicrixNetwork">
                            <img src={require('../../assets/medium.png')} alt="logo" className='h-5 md:h-10 w-5 md:w-10 p-2 circle border border-gray-600' />
                        </a>
                        <a href="https://www.reddit.com/user/SicrixNetwork">
                            <img src={`${url}/reddit.svg`} alt="logo" className='h-5 md:h-10 circle border border-gray-600' />
                        </a>
                        <a href="https://t.me/sicrixNetwork">
                            <img src={`${url}/telegram.svg`} alt="logo" className='h-5 md:h-10 circle border border-gray-600' />
                        </a>
                    </div>
                    <div className='w-[30%] h-[1px] bg-[#89d7fb33] hidden md:block' />
                </div>
                <div className='flex items-start md:items-center md:justify-between mx-auto max-w-[1100px] flex-col md:flex-row gap-2 my-5'>
                    <p className='text-xs md:text-sm text-gray-400 nunito text-center md:text-left w-full md:w-[650px]'>Copyright © 2024 Sicrix. All rights reserved.</p>
                    <div className='text-xs md:text-sm gap-1 flex-center monts text-gray-400 text-center w-full md:w-auto'>
                        <a href="https://doc.sicrix.network/legal/privacy-policy">Privacy Notice |</a>
                        <a href="https://doc.sicrix.network/legal/disclaimer">Disclaimer</a>
                    </div>
                </div>
            </div>
        </div>
    )
}
